<template>
    <div>
      <!--headerpage/-->
      <div v-if="['sm','xs'].includes($store.getters['app/currentBreakPoint'])" class="text-center">
      <h3 class="text-white font-weight-bolder">Explore Quest</h3>
        </div> 
      <header-part 
      v-if="['sm','xs'].includes($store.getters['app/currentBreakPoint'])?false:true"
      :title="'Explore Quest'"
      :image="require('@/assets/images/illustration/quest.png')"
      :access="1"
      :paragraph="'Complete social and creative quests to earn rewards. Your rewards can be used to buy different Items from Eliteemperors store.'"></header-part>

      <div v-if="loadingFeatured" class="mt-2 text-center text-success">
           <b-spinner
        style="width: 3rem; height: 3rem;"
        class="mr-1"
        label="Large Spinner"
      />
        </div>
        <div v-else>
    <b-card class="mt-2">
            <quest :quests="quests" :progress="progress" :notstarted="0"/>
    </b-card>
    </div>
    </div>
</template>
<script>
import {BCard,BCardText,BCollapse,BTabs,BTab,BNavItem,BButton,BSpinner,BRow,BCol,BCardBody,BCardImg} from "bootstrap-vue"
import Quest from "@/components/Quest.vue"
import Ripple from "vue-ripple-directive"
import SellerService from "@/services/SellerService";
import Headerpage from "@/components/Headerpage.vue"
import HeaderPart from "@/components/HeaderPart.vue"
export default {
    directives:{Ripple},
    components:{BCard,BCardText,BCollapse,BTabs,BTab,BNavItem,BButton,Quest,BSpinner,Headerpage,
    BRow,BCol,BCardBody,BCardImg,HeaderPart},
    data(){
      return{
        quests:[],
        questinprogress:[],
        loadingFeatured:true,
        progress:[],
        notStarted:[],
        completed:[]
      }
    },
    mounted(){
      this.getQuests()
      this.getQuestProgress()
      this.insertlg()
    },
    methods:{
      getQuests:async function(){
        const qu=(await SellerService.getQuests(JSON.parse(localStorage.getItem("auth")).discordId,1)).data
        var date = new Date();
         var now_utc = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(),
                date.getUTCDate(), date.getUTCHours(),
                date.getUTCMinutes(), date.getUTCSeconds());
                this.quests=qu;
        // for (let x = 0; x < qu.length; x++) {
        //   if (qu[x].options.items.length>0 && now_utc>=new Date(qu[x].options.startDate)) {
        //   this.quests.push(qu[x]) 
        //   }
        // }
        this.loadingFeatured=false
      },
      getQuestProgress:async function(){
        const pro=(await SellerService.getQuestProgress(JSON.parse(localStorage.getItem("auth")).discordId)).data
        this.progress=pro
      },
      insertlg:async function(){
        const d=(await SellerService.insertLgbyUser(JSON.parse(localStorage.getItem("auth")).discordId,this.$t("curlang")))
      }
    }
}
</script>
<style lang="scss" >
@import '@core/scss/vue/pages/page-profile.scss';

.titles{
  color:#FFFFFF;
  font-style: normal;
font-weight: 600;
font-size: 24px;
line-height: 32px;
}
</style>